import type { IVisitFormInputField } from '@einfachgast/shared';
import { translate } from '@/plugins/translator';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export function useFieldname (field: IVisitFormInputField) {
  return computed(() => {
    if (field.fixed === true) {
      // this is only for the static name field 
      // cause u cannot change the name of the field
      const { t } = useI18n();
      return t('fullname');
    }
    return translate(field.name);
  });
}